<template>
  <section id="process-section" class="section">
    <section-title :text="'Jak przebiega proces finansowania?'" />
    <div id="infos-3" class="grid-container">
      <article class="item1">
        <div class="info-heading">
          <h3 class="article-title">1</h3><hr/>
        </div>
        <h3 class="article-title">Analiza</h3>
        <p class="article-text">Integracja pomiędzy brutto.pl a&nbsp;Twoim kontem w&nbsp;systemie Autopay (PayAutopay), które merchanci Shoper znają jako Shoper&nbsp;Płatności, pozwoli zweryfikować Twoje wyniki sprzedażowe i&nbsp;na ich podstawie określić maksymalną możliwą kwotę finansowania.</p>
      </article>
      <article class="item2">
        <div class="info-heading">
          <h3 class="article-title">2</h3><hr/>
        </div>
        <h3 class="article-title">Wybór oferty</h3>
        <p class="article-text">Wskaż wysokość finansowania, jakie chcesz uzyskać, zapoznaj się z&nbsp;warunkami oferty i kosztami. Potwierdź chęć zawarcia umowy, by&nbsp;otrzymać potrzebne środki.</p>
      </article>
      <article class="item3">
        <div class="info-heading">
          <h3 class="article-title">3</h3><hr/>
        </div>
        <h3 class="article-title">Wypłata środków</h3>
        <p class="article-text">Finansowanie od&nbsp;razu trafi na&nbsp;Twój rachunek bankowy (wskazany na&nbsp;Twoim koncie w&nbsp;systemie Autopay (PayAutopay) w&nbsp;dniu, w&nbsp;którym podpiszesz umowę.</p>
      </article>
      <article class="item4">
        <div class="info-heading">
          <h3 class="article-title">4</h3><hr/>
        </div>
        <h3 class="article-title">Spłata</h3>
        <p class="article-text">Raty będą pobierane automatycznie z&nbsp;Twojego salda w&nbsp;PayAutopay, a&nbsp;ich wysokość będzie zależeć od&nbsp;aktualnej sprzedaży. Na&nbsp;całkowitą spłatę finansowania masz 13 miesięcy.</p>
      </article>
    </div>
    <button-open-dialog />
  </section>
</template>

<script>
import SectionTitle from '../../../components/common/SectionTitle'
import ButtonOpenDialog from '../../../components/common/ButtonOpenDialog'

export default {
  name: 'InfosProcessSection',
  components: {
    ButtonOpenDialog,
    SectionTitle
  }
}
</script>
