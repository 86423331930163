<template>
  <section id="profits-section" class="section">
    <section-title :text="'Co zyskujesz z finansowaniem od brutto.pl dla swojego sklepu?'" />
    <div id="infos-1" class="grid-container">
      <article class="item1 info-box">
        <h3 class="article-title">Elastyczność</h3>
        <p class="article-text">Finansowanie możeszprzeznaczyć na&nbsp;dowolny cel związany z&nbsp;prowadzeniem firmy: podatki, wypłaty dla pracowników, zakup towaru, kampanie reklamowe i&nbsp;wiele więcej.</p>
        <img class="info-icon" src="@/assets/images/icon_60px_19_blue.svg" alt="">
      </article>
      <article class="item2 info-box">
        <h3 class="article-title">Swobodę działania</h3>
        <p class="article-text">Dodatkowe środkipozwolą Ci z&nbsp;większym spokojem zarządzać e-biznesem i rozwijać go w&nbsp;wybranym przez Ciebie kierunku.</p>
        <img class="info-icon" src="@/assets/images/icon_60px_27_blue.svg" alt="">
      </article>
      <article class="item3 info-box">
        <h3 class="article-title">Niezależność</h3>
        <p class="article-text">Zabezpieczenie finansowe pomoże Ci podejmować odważne decyzje i&nbsp;osiągać cele, które do&nbsp;tej pory były odkładane na&nbsp;przyszłość.</p>
        <img class="info-icon" src="@/assets/images/icon_60px_32_blue.svg" alt="">
      </article>
    </div>
  </section>
</template>

<script>
import SectionTitle from '../../../components/common/SectionTitle'

export default {
  name: 'InfosProfitSection',
  components: {
    SectionTitle
  }
}
</script>
